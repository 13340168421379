@import '../../../stylesheets/shared/content/fonts.postcss';
@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.Register, .CateringRegister, .Login {

  :global(.Box):global(.Box--white) {
    padding: 1.5em;
    border: 1px solid $grey;
  }

  h1 {
    @mixin gordita-medium;
    font-size: 30px;
    letter-spacing: 0;
  }

  label {
    @mixin gordita-medium;
  }

  .Button__facebook,
  .Button__google {
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 10px;
    }
  }

  .Button__google {
    border: 1px solid #747775;
    color: #1F1F1F;

    &:hover {
      background-color: white;
      border: 1px solid #747775;
      color: #1F1F1F;
    }

    img {
      width: 17px;
      padding-bottom: 4px;
    }
  }

  .Button__facebook {
    color: white;
    background-color: #1877F2;
    border: none;

    &:hover {
      color: white;
      background-color: #1877F2;
    }

    img {
      width: 20px;
      padding-bottom: 5px;
    }
  }

  .Button__apple {
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    background-color: black;

    &:hover {
      background-color: black;
    }
  }
}

.OrRule {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: 'OR';
    transform: translateX(-50%) translateY(-50%);
    font-size: 0.85rem;
    background: white;
    padding: 0.5rem;
  }
}